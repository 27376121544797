body {
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
  background: url("images/test-tube-g1ed7a476b_1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

label {
  display: block;
  margin-bottom: 15px;
}

.center {
  text-align: center;
}

a {
  color: #165a72;
}

a:visited {
  color: #6c25be;
}

.domain {
  margin-top: 0;
  margin-bottom: 30px;
  border-bottom: 2px solid #1e7898;
  cursor: pointer;
}

.spacer {
  margin: 25px;
}

.chip > a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.donate {
  padding: 10px;
  border: 2px solid #be2558;
  background-color: #fcfcfc;
  text-align: center;
  border-radius: 5px;
}

.band {
  color: white;
  padding: 5px;
  background-color: #6c25be;
  text-align: center;
}

.chip {
  background-color: #7cc0d8;
  display: inline;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
}

.purple {
  background-color: #6c25be;;
}

.huge {
  font-size: 6rem;
}

.bold {
  font-weight: bold;
}

.navigation {
  margin-top: 30px;
  margin-bottom: 20px;
}

.navigation > a {
  text-decoration: none;
  color: black;
}

input,
select {
  font-size: 1.2rem;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="date"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

input[type="checkbox"],
select {
  margin: 8px 8px 8px 0;
}

.inputLikeText {
  display: block;
  font-size: 1.2rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.validationError {
  display: block;
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.submitValidationError {
  font-size: 1rem;
  font-weight: bold;
}

.validationErrorInput {
  border: 2px solid red;
  border-radius: 4px;
}

.noAppointments {
  margin-bottom: 20px;
  padding: 5px;
  border: 2px solid red;
  border-radius: 4px;
  font-weight: bold;
}

.wrapper {
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px;
  max-width: 700px;
  text-align: left;
  border-radius: 8px;
}

.formWrapper {
  background-color: white;
}

.indented {
  margin-left: 25px;
  margin-top: -20px;
}